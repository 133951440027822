// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";
const config = {
	useSystemColorMode: false,
	initialColorMode: "light",
};

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
	blue: {
		50: "#DCF4FF",
		100: "#A9E4FE",
		200: "#77D5FD",
		300: "#45C5FC",
		400: "#13B5FC",
		500: "#0397D7",
		600: "#0275A6",
		700: "#025174",
		800: "#012E41",
		900: "#000B0F",
	},
};

const sizes = {
	container: {
		sm: "640px",
		md: "768px",
		lg: "1024px",
		xl: "1280px",
		xxl: "1920px",
	},
};

const fontSizes = {
	xs: "0.75rem",
	sm: "0.875rem",
	md: "1rem",
	lg: "1.125rem",
	xl: "1.25rem",
	"2xl": "1.5rem",
	"3xl": "1.875rem",
	"4xl": "2.25rem",
	"5xl": "3rem",
	"6xl": "3.75rem",
	"7xl": "4.5rem",
	"8xl": "6rem",
	"9xl": "8rem",
};

const components = {};

const theme = extendTheme({
	config,
	styles: {
		global: {
			p: {
				lineHeight: "1.9",
			},
		},
	},
	fonts: {
		body: "'Avalon', sans",
		heading: "'Avalon', sans",
		// mono: "Menlo, monospace",
	},
	colors,
	components,
	sizes,
	fontSizes,
});

export default theme;
